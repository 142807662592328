
<template>
  <div
    id="modalInvoice"
    class="barcode-dialog text-center"
  >
    <v-btn @click="generateReport(item)">
      Print
    </v-btn>
    <v-row>
      <div class="col-md-6">
        <b>{{ itemDetails.label }}</b>
        <div class="image">
          <img
            class="full-width"
            :src="itemDetails.barcode"
          >
        </div>

        <b>{{ itemDetails.item_total_after_profit }}</b>
        <div class="d-flex justify-center">
          <b class="mx-2">
            {{ itemDetails.metal.name }}
          </b>
          <b class="mx-2">
            {{ itemDetails.gold_weight }}
          </b>
        </div>
      </div>
      <div
        v-if="itemDetails.stones.length > 0"
        class="col-md-6"
      >
        <div
          v-for="(item, i) in itemDetails.stones"
          :key="i"
          class="d-flex justify-space-between align-baseline"
        >
          <b>{{ item.quantity }}</b>
          <b>{{ item.code }}</b>
          <b>{{ item.weight }}</b>
        </div>
      </div>
      <template>
        <div>
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="Barcode"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            @progress="onProgressPdf($event)"
          >
            <printBarcode
              slot="pdf-content"
              :item-details="itemDetails"
            />
          </vue-html2pdf>
          <custom-progress
            v-show="showProgress"
            :progress="progressNumber"
          />
        </div>
      </template>
    </v-row>
  </div>
</template>
<script>
  import VueHtml2pdf from 'vue-html2pdf'
  import CustomProgress from '../component/progress.vue'
  import printBarcode from './printBarcode.vue'
  export default {
    components: {
      VueHtml2pdf,
      CustomProgress,
      printBarcode,
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['itemDetails'],
    data () {
      return {
        autoWidth: true,
        filename: 'Barcode',
        progressNumber: 0,
        showProgress: false,
        pdfDataLoading: false,
      }
    },
    methods: {
      print () {
        const modal = document.getElementById('modalInvoice')
        const cloned = modal.cloneNode(true)
        let section = document.getElementById('print')

        if (!section) {
          section = document.createElement('div')
          section.id = 'print'
          document.body.appendChild(section)
        }

        section.innerHTML = ''
        section.appendChild(cloned)
        window.print()
      },

      onProgressPdf: function (data) {
        this.progressNumber = data
        if (data === 100) {
          setTimeout(() => {
            this.showProgress = false
          }, 1000)
        }
      },
      generateReport: async function () {
        this.progressNumber = 0
        this.pdfDataLoading = true
        this.showProgress = true
        this.progressNumber = 40
        this.progressNumber = 40
        // this.itemDetails = item
        if (this.itemDetails) {
          this.progressNumber = 20
          await this.$refs.html2Pdf.generatePdf()
          console.log('this.itemDetails', this.itemDetails)
          this.successMessage = 'تمت تحميل الملف بنجاح'
          this.successSnackbar = true
        } else {
          this.errorMessage = 'يوجد مشكلة في تحميل الملف برجاء المحاولة مرة اخري'
          this.errorSnackbar = true
        }
      },
    },
  }
</script>
<style lang="scss">
.image{
  width: 100%;
  .full-width{
    width: 100%;
  }
}
</style>
