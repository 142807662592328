<template>
  <div>
    <img
      class="full-width"
      :src="itemDetails.barcode"
    >
  </div>
</template>
<script>
  export default {
    props: ['itemDetails'],
  }
</script>
<style lang="scss">
  .full-width{
    width: 100%;
  }
</style>
