<!-- eslint-disable vue/valid-v-model -->

<template>
  <div class="barcode-dialog">
    <v-row>
      <div class="col-md-6">
        <p>{{ productData.category.name + ' ' + productData.label }}</p>
        <div class="image">
          <v-img
            class="full-width my-2"
            :src="productData.barcode"
            :aspect-ratio="1/1"
            max-height="150"
          />
          <v-img
            class="full-width img-contain v-image__image--contain"
            :src="'https://api.newturquoisejewelry.com/img/products/'+productData.image"
            alt="Product Image"
            :aspect-ratio="1/1"
            max-height="200"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div
          v-if="itemDetails.status"
          class="text-end status"
        >
          <v-chip
            v-if="itemDetails.status.name == 'available'"
            class="text-center"
            color="success"
            x-large
            text-color="white"
            label
          >
            <h3>{{ productData.status.name }}</h3>
          </v-chip>
          <v-chip
            v-else-if="productData.status.name === 'pending'"
            class="text-center"
            color="orange"
            x-large
            label
            text-color="white"
          >
            <h3>{{ productData.status.name }}</h3>
          </v-chip>
          <v-chip
            v-else
            class="text-center"
            color="error"
            x-large
            label
            text-color="white"
          >
            <h3>{{ productData.status.name }}</h3>
          </v-chip>
        </div>
        <div v-else>
          test
        </div>
        <div class="py-3 d-flex justify-space-between">
          <div class="br-1">
            <b>Category</b>
            <p>{{ productData.category.name }}</p>
          </div>
          <div class="br-1">
            <b>Code</b>
            <p>{{ productData.label }}</p>
          </div>
          <div class="br-1">
            <b>Branch</b>
            <p>{{ productData.branch.name }}</p>
          </div>
          <div class="br-1">
            <b>Date</b>
            <p>{{ productData.product_date }}</p>
          </div>
        </div>
        <div class="text-center py-3 d-flex justify-space-around">
          <v-card
            width="130"
            class="pa-3"
          >
            <b>Gold Weight</b>
            <p>(gm)</p>
            <p v-if="itemDetails.action === 'view'">
              {{ productData.gold_weight }}
            </p>
            <v-text-field
              v-if="itemDetails.action === 'edit'"
              v-model="productData.gold_weight"
              outlined
              dense
            />
          </v-card>
          <v-card
            width="100"
            class="pa-3"
          >
            <b>Gold Price</b>
            <p>($)</p>
            <p v-if="itemDetails.action === 'view'">
              {{ productData.gold_price }}
            </p>
            <v-text-field
              v-if="itemDetails.action === 'edit'"
              v-model="productData.gold_price"
              outlined
              dense
            />
          </v-card>
          <v-card
            width="100"
            class="pa-3"
          >
            <b>Total</b>
            <p>($)</p>
            <p v-if="itemDetails.action === 'view'">
              {{ productData.gold_total }}
            </p>
            <p v-if="itemDetails.action === 'edit'">
              {{ ( productData.gold_price * productData.gold_weight) }}
            </p>
          </v-card>
        </div>
      </div>
      <div
        v-if="itemDetails.stones.length > 0"
        class="col-12 py-6"
      >
        <div class="d-flex justify-space-between">
          <h3>{{ 'Stones: ' + productData.stones.length + ' stone' }}</h3>
          <v-btn
            v-if="itemDetails.action === 'edit'"
            color="success"
            @click="newStone()"
          >
            Add
          </v-btn>
        </div>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  Name
                </th>
                <th class="text-center">
                  Quantity
                </th>
                <th class="text-center">
                  Weight (CTS)
                </th>
                <th class="text-center">
                  Cost ($)
                </th>
                <th class="text-center">
                  Setting
                </th>
                <th class="text-center">
                  Total
                </th>
                <th
                  v-if="itemDetails.action === 'edit'"
                  class="text-center"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in productData.stones"
                :key="i"
              >
                <td class="text-center">
                  <span v-if="itemDetails.action === 'view'">{{ item.name }}</span>
                  <v-select
                    v-if="itemDetails.action === 'edit'"
                    v-model="item.name"
                    :items="stoneList"
                    :label="$t('settings.stones')"
                    item-text="name"
                    outlined
                    disabled
                    dense
                  />
                </td>
                <td class="text-center">
                  <span v-if="itemDetails.action === 'view'">{{ item.quantity }}</span>
                  <v-text-field
                    v-if="itemDetails.action === 'edit'"
                    v-model="item.quantity"
                    outlined
                    dense
                    class="width70 ma-auto"
                  />
                </td>
                <td class="text-center">
                  <span v-if="itemDetails.action === 'view'">{{ item.weight }}</span>
                  <v-text-field
                    v-if="itemDetails.action === 'edit'"
                    v-model="item.weight"
                    outlined
                    dense
                    class="width70 ma-auto"
                  />
                </td>
                <td class="text-center">
                  <span v-if="itemDetails.action === 'view'">{{ item.price }}</span>
                  <v-text-field
                    v-if="itemDetails.action === 'edit'"
                    v-model="item.price"
                    outlined
                    dense
                    class="width70 ma-auto"
                  />
                </td>
                <td class="text-center">
                  <span v-if="itemDetails.action === 'view'">{{ item.setting }}</span>
                  <v-text-field
                    v-if="itemDetails.action === 'edit'"
                    v-model="item.setting"
                    outlined
                    dense
                    class="width70 ma-auto"
                  />
                </td>
                <td class="text-center">
                  <span v-if="itemDetails.action === 'view'">{{ item.total }}</span>
                  <span v-if="itemDetails.action === 'edit'">
                    {{ item.total = (+item.price * +item.weight) + +item.setting + +item.quantity }}
                  </span>
                </td>
                <td
                  v-if="itemDetails.action === 'edit'"
                  class="text-center"
                >
                  <v-tooltip
                    bottom
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-btn
                        small
                        fab
                        outlined
                        class="mx-2"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="changeStone(item)"
                      >
                        <v-icon>
                          fa-pen
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('actions.changeStone') }}
                  </v-tooltip>
                  <v-tooltip
                    bottom
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-btn
                        small
                        fab
                        outlined
                        class="mx-2"
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        @click="confirmDeleteItem(item)"
                      >
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('actions.delete') }}
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div
        v-else
        class="col-12"
      >
        <v-alert type="warning">
          NO STONE AVAILABLE TO THIS PRODUCT.
        </v-alert>
      </div>
      <div
        class="col-12"
      >
        <v-row>
          <div class="col-md-6">
            <v-card
              class="pa-3 text-center"
              height="100"
              color="blue-grey"
              dark
            >
              <h4>COST</h4>
              {{ Math.ceil(( productData.gold_price * productData.gold_weight) + (productData.stones.reduce(
                (p, item) => p + +item.total,
                0
              ))) }}
            </v-card>
          </div>
          <div class="col-md-6">
            <v-card
              class="pa-3 text-center"
              color="blue-grey"
              dark
              height="100"
            >
              <h4 class="d-flex justify-center">
                Final Cost *
                <span v-if="itemDetails.action === 'view'"> {{ '  ' + productData.profit_percent + ' ' }} </span>
                <div v-if="itemDetails.action === 'edit'">
                  <v-text-field
                    v-model="productData.profit_percent"
                    outlined
                    dense
                    class="width70"
                  />
                </div>
              </h4>
              {{ Math.ceil((( productData.gold_price * productData.gold_weight) + (productData.stones.reduce(
                (p, item) => p + +item.total,
                0
              ))) * productData.profit_percent) }}
            </v-card>
          </div>
          <div
            v-if="itemDetails.action === 'edit'"
            class="col-12 text-center"
          >
            <v-btn
              color="success"
              @click="update(productData)"
            >
              update
            </v-btn>
          </div>
        </v-row>
      </div>
    </v-row>
    <v-dialog
      v-model="stoneDialog"
      width="800"
    >
      <v-card>
        <v-card-text>
          <v-form>
            <v-row>
              <div class="col-12">
                <v-select
                  v-if="itemDetails.action === 'edit'"
                  v-model="stoneDetails"
                  :items="stoneList"
                  :label="$t('settings.stone.name')"
                  item-text="name"
                  outlined
                  dense
                  return-object
                />
              </div>
              <div class="col-md-6">
                <v-text-field
                  v-if="itemDetails.action === 'edit'"
                  :v-model="stoneDetails.quantity ? stoneDetails.quantity : stoneDetails.quantity = 0"
                  :label="$t('settings.stone.quantity')"
                  outlined
                  dense
                  class="ma-auto"
                />
              </div>
              <div class="col-md-6">
                <v-text-field
                  v-if="itemDetails.action === 'edit'"
                  v-model="stoneDetails.weight"
                  :label="$t('settings.stone.weight')"
                  outlined
                  dense
                  class="ma-auto"
                />
              </div>
              <div class="col-md-6">
                <v-text-field
                  v-if="itemDetails.action === 'edit'"
                  v-model="stoneDetails.price"
                  :label="$t('settings.stone.price')"
                  outlined
                  dense
                  class="ma-auto"
                />
              </div>
              <div class="col-md-6">
                <v-text-field
                  v-if="itemDetails.action === 'edit'"
                  v-model="stoneDetails.setting"
                  :label="$t('settings.stone.setting')"
                  outlined
                  dense
                  class="ma-auto"
                />
              </div>
              <v-btn
                v-if="add === true"
                class="mx-auto my-auto d-flex"
                color="indigo"
                @click="AddNewStone(stoneDetails)"
              >
                {{ $t('actions.save') }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDailog"
      width="500"
    >
      <v-card
        class="text-center"
      >
        <base-material-card
          :title="$t('actions.deleteConfirmation')"
          color="error"
          class="pt-12"
        >
          <v-card-text class="mt-2">
            {{ $t('actions.areYouSureDelete') + ' ' + stoneDetails.name + ' ' + $t('actions.?') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green"
              outlined
              @click="deleteItem(stoneDetails)"
            >
              {{ $t('actions.delete') }}
            </v-btn>
            <v-btn
              color="error"
              outlined
              @click="deleteDailog = false"
            >
              {{ $t('actions.close') }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  const stonesServices = ServiceFactory.get('stones')
  const mainStockServices = ServiceFactory.get('mainStock')
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['itemDetails'],
    data () {
      return {
        stoneList: [],
        editedIndex: -1,
        stoneDetails: {},
        stoneData: {},
        // productData: {},
        stoneDialog: false,
        deleteDailog: false,
        add: false,
      }
    },
    computed: {
      productData () {
        return this.itemDetails
      },
    },
    watch: {
      stoneDetails () {
        this.productData.stones.find(i => {
          if (this.stoneData.id === i.id) {
            const item = this.productData.stones.indexOf(this.stoneData)
            this.productData.stones.splice(item, 1)
            this.productData.stones.push(this.stoneDetails)
            this.stoneData = this.stoneDetails
          }
        })
      },
    },
    created () {
      this.productData = this.itemDetails
      console.log('this.productData', this.productData)
      this.getStoneList()
    },
    methods: {
      confirmDeleteItem (data) {
        this.stoneDetails = data
        this.deleteDailog = true
      },
      async deleteItem (stoneDetails) {
        this.deleteDailog = false
        setTimeout(() => {
          this.editedIndex = this.productData.stones.indexOf(stoneDetails)
          this.productData.stones.splice(this.editedIndex, 1)
        }, 500)
      },
      newStone () {
        this.stoneDetails = {}
        this.stoneDialog = true
        this.add = true
      },
      AddNewStone (stoneDetails) {
        this.stoneDialog = false
        this.add = false
        this.productData.stones.push(stoneDetails)
        this.stoneDetails = {}
      },
      changeStone (data) {
        this.stoneDetails = data
        this.stoneData = data
        this.add = false
        this.stoneDialog = true
      },
      async update (item) {
        console.log('item', item)
        const update = await mainStockServices.updateData(item)
        console.log('update', update)
      },
      async getStoneList () {
        const list = await stonesServices.getActiveList()
        console.log('stoneList', list)
        this.stoneList = list.data
        this.dataLoading = false
      },
    },
  }
</script>
<style lang="scss">
.image{
  width: 100%;
  .full-width{
    width: 100%;
    //max-height: 300px;
  }
  .img-contain{
    .v-image__image--cover{
      background-size: contain !important;
    }
  }
}
.width70{
  width: 70px;
}
</style>
