
<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card class="py-5">
      <v-card-title>
        {{ $t('transfer.makeNewTransfer') }}
      </v-card-title>
      <template>
        <v-form
          v-model="valid"
          @submit.prevent="submitForm()"
        >
          <v-container fluid>
            <v-row class="mx-md-6 px-md-6">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-if="itemDetails"
                  v-model="itemDetails.label"
                  required
                  :label="$t('filter.label')"
                  disabled
                  dense
                  outlined
                />
                <v-autocomplete
                  v-else
                  v-model="data.label"
                  :items="items"
                  :loading="isLoading"
                  :search-input.sync="search"
                  hide-no-data
                  hide-selected
                  outlined
                  dense
                  item-text="label"
                  item-value="id"
                  :label="$t('filter.label')"
                  :placeholder="$t('filter.startTypingToSearch')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-if="itemDetails"
                  v-model="itemDetails.branch.name"
                  required
                  :label="$t('transfer.currentBranch')"
                  disabled
                  dense
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="data.branch_id"
                  :items="branches"
                  :label="$t('settings.branches')"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              class="mx-auto my-auto d-flex"
              color="indigo"
              :loading="loading"
              :disabled="disabled"
            >
              {{ this.$route.params.id ? $t('actions.save') : $t('actions.add') }}
            </v-btn>
          </v-container>
        </v-form>
      </template>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  const branchesService = ServiceFactory.get('branches')
  const transferService = ServiceFactory.get('transfer')
  const mainStockServices = ServiceFactory.get('mainStock')

  export default {
    name: 'MakeNewTransfer',
    // eslint-disable-next-line vue/require-prop-types
    props: ['itemDetails'],
    data: (vm) => ({
      descriptionLimit: 30,
      search: null,
      isLoading: false,
      entries: [],
      model: null,
      dataLoading: false,
      valid: false,
      data: {
        label: null,
        branch_id: null,
        currentBranch: null,
      },
      productList: [],
      branches: [],
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      loading: false,
      disabled: false,
    }),
    computed: {
      items () {
        return this.entries.map(entry => {
          const label = entry.label.length > this.descriptionLimit
            ? entry.label.slice(0, this.descriptionLimit) + '...'
            : entry.label
          return Object.assign({}, entry, { label })
        })
      },
    },

    watch: {
      async search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        const listData = await mainStockServices.getAllItems('all', 1)
        console.log('listData.length', listData.data.length)
        this.entries = listData.data
        this.count = listData.data.length
        this.isLoading = false
      },
    },
    created () {
      this.fetchAllBranches()
    },
    // mounted () {
    //   if (this.itemDetails) {
    //     console.log('this.itemDetails', this.itemDetails)
    //     this.data.label = this.itemDetails.label
    //   }
    // },
    methods: {
      async  submitForm () {
        this.loading = true
        this.disabled = true
        console.log('this.data', this.data)
        const productId = this.itemDetails ? { product_id: this.itemDetails.id } : { product_id: this.data.label }
        this.newItem(this.data.branch_id, productId)
      },
      async newItem (branchId, productId) {
        const item = await transferService.makeNewTransfer(branchId, productId)
        try {
          if (item.status === 'success') {
            this.successMessage = 'Transfer Added Successfuly'
            this.successSnackbar = true
            // this.$router.reload()
            setTimeout(() => {
              // this.$router.push('/transfer/list')
              // this.$router.reload()
              window.location.reload(true)
            }, 1500)
          } else {
            this.errorMessage('something Error')
            this.errorSnackbar = true
          }
        } catch (err) {
          console.log('err', err)
          this.errorMessage('something Error')
          this.errorSnackbar = true
        }
        this.disabled = false
        this.loading = false
      },
      async fetchAllBranches () {
        this.dataLoading = true
        const list = await branchesService.getActiveList()
        this.branches = list.data
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
</style>
