<!-- eslint-disable vue/no-template-shadow -->
<!-- eslint-disable vue/no-parsing-error -->
<!--  eslint-disable-->
<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-text>
        <filterComponents
          @doFilter="setFilter"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        {{ $t('stock.mainStock') }}
        <v-spacer />
        <v-btn
          v-if="this.$route.name === 'stockReport'"
          outlined
          class="mx-2"
          color="primary"
          @click="exportExel()"
        >
          {{ $t('actions.print') }}
        </v-btn>
        <router-link
          :to="{ path: '/stock/add-new-item'}"
          color="primary"
        >
          <v-btn
            outlined
            class="mx-2"
            color="primary"
          >
            {{ $t('actions.add') }}
          </v-btn>
        </router-link>
      </v-card-title>
      <v-card-text v-show="!dataLoading">
        <v-data-table
        :loading="dataLoading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :search="search"
        :items="list"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :options.sync="options"
        :server-items-length="total"
        :page-count="numberOfPages"
      >
        <template
        v-slot:[`item.status`]="{ item }"
      >
        <v-chip
          v-if="item.status.name === 'available'"
          class="text-center"
          color="success"
          text-color="white"
        >
          {{ item.status.name }}
        </v-chip>
        <v-chip
          v-else-if="item.status.name === 'pending'"
          class="text-center"
          color="orange"
          text-color="white"
        >
          {{ item.status.name }}
        </v-chip>
        <v-chip
          v-else
          class="text-center"
          color="error"
          text-color="white"
        >
          {{ item.status.name }}
        </v-chip>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img
          width="70"
          height="70"
          :src="'https://api.newturquoisejewelry.com/img/products/'+ item.image"
        />
      </template>

      <template
        v-slot:no-data
      >
        <p>{{ $t('actions.noData') }}</p>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              fab
              outlined
              class="mx-2"
              color="blue"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-tooltip
                  bottom
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      small
                      fab
                      outlined
                      class="mx-2"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                      @click="openLabelDialog(item)"
                    >
                      <v-icon>
                        fa-barcode-read
                      </v-icon>
                    </v-btn>
                  </template>
                  {{ $t('stock.label') }}
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-title>
                <v-tooltip
                  bottom
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      small
                      fab
                      outlined
                      class="mx-2"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  {{ $t('actions.delete') }}
                </v-tooltip>
              </v-list-item-title>
            </v-list-item> -->
            <v-list-item v-if="item.status.name === 'available'">
              <v-list-item-title>
                <v-tooltip
                  bottom
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      small
                      fab
                      outlined
                      class="mx-2"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                      @click="openMakeTransfer(item)"
                    >
                      <v-icon>
                        mdi-share
                      </v-icon>
                    </v-btn>
                  </template>
                  {{ $t('transfer.makeNewTransfer') }}
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.status.name === 'available'">
              <v-list-item-title>
                <v-tooltip
                  bottom
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      small
                      fab
                      outlined
                      class="mx-2"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                      @click="openMakeRessaller(item)"
                    >
                      <v-icon>
                        fa-user-tie
                      </v-icon>
                    </v-btn>
                  </template>
                  {{ $t('ressaller.addResaller') }}
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.status.name === 'available'">
              <v-list-item-title>
                <v-tooltip
                  bottom
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      small
                      fab
                      outlined
                      class="mx-2"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                      @click="openMoreDetails(item)"
                    >
                      <v-icon>
                        fa-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  {{ $t('stock.moreDetails') }}
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.status.name === 'available'">
              <v-list-item-title>
                <v-tooltip
                  bottom
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      small
                      fab
                      outlined
                      class="mx-2"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                      @click="openEditDetails(item)"
                    >
                      <v-icon>
                        fa-pen
                      </v-icon>
                    </v-btn>
                  </template>
                  {{ $t('actions.edit') }}
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.status.name === 'available'">
              <v-list-item-title>
                <v-tooltip
                  bottom
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <router-link
                      :to="{ path: '/sales/salesForm/' + item.id}"
                      color="primary"
                    >
                      <v-btn
                        small
                        fab
                        outlined
                        class="mx-2"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>
                          fa-hand-holding-magic
                        </v-icon>
                      </v-btn>
                    </router-link>
                  </template>
                  {{ $t('actions.makeSale') }}
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      </v-data-table>
      </v-card-text>
      <v-card-text
        v-show="dataLoading"
        class="ma-auto text-center"
      >
        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog
      v-model="labelDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          <!-- {{ $t('stock.label') }} -->
        </v-card-title>

        <v-card-text class="text-center">
          <barCodeVue :item-details="itemDetails" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="MakeTransferDialog"
      width="1200"
    >
      <v-card>
        <v-card-text class="text-center">
          <transfer :item-details="itemDetails" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="MakeRessallerDialog"
      width="1200"
    >
      <v-card>
        <v-card-text class="text-center">
          <ressaller :item-details="itemDetails" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="detailsDialog"
      width="1200"
    >
      <v-card>
        <v-card-text>
          <moreDetails :item-details="itemDetails" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import barCodeVue from './barCode.vue'
  import transfer from '../transfer/Form.vue'
  import ressaller from '../ressaller/Form.vue'
  import moreDetails from './detailsForm.vue'
  import filterComponents from './filter.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  const mainStockServices = ServiceFactory.get('mainStock')
  export default {
    name: 'Companies',
    components: {
      filterComponents,
      barCodeVue,
      transfer,
      ressaller,
      moreDetails,
    },
    data: (vm) => ({
      search: '',
      dataLoading: false,
      page: 0,
      total: 0,
      numberOfPages: 0,
      filter: {
        branch_id: null,
        category_id: null,
        label: null,
        status_id: null,
        metal_type: null,
      },
      path: 'https://api.newturquoisejewelry.com/img/products/',
      options: {},
      list: [],
      Roles: [],
      loading: false,
      deleteDailog: false,
      itemDetails: {},
      labelDialog: false,
      MakeTransferDialog: false,
      MakeRessallerDialog: false,
      detailsDialog: false,
      editDialog: false,
      editedIndex: -1,
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      disabled: false,
      headers: [
        {
          text: vm.$t('settings.id'),
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: vm.$t('stock.image'), sortable: false, value: 'image' },
        { text: vm.$t('stock.salePrice'), sortable: false, value: 'item_total_after_profit' },
        { text: vm.$t('stock.category'), sortable: false, value: 'category.name' },
        { text: vm.$t('stock.code'), sortable: false, value: 'label' },
        { text: vm.$t('stock.branch'), sortable: false, value: 'branch.name' },
        { text: vm.$t('stock.metalType'), sortable: false, value: 'metal.name' },
        { text: vm.$t('stock.goldWeight'), sortable: false, value: 'gold_weight' },
        { text: vm.$t('stock.createdDate'), sortable: false, value: 'product_date' },
        { text: vm.$t('stock.status'), sortable: false, value: 'status' },
        { text: vm.$t('actions.actions'), value: 'actions', sortable: false },
      ],
      header: ['ID', 'Category', 'Code', 'Branch', 'Metal Type', 'Gold Weight', 'Sale Price', 'Date', 'Status'],
      bookType: 'xlsx',
      autoWidth: true,
      filename: 'Stock List Report',
    }),
    watch: {
      options: {
        handler () {
          this.fetchAllItems()
        },
      },
    },
    methods: {
      openLabelDialog (item) {
        this.labelDialog = true
        this.itemDetails = item
      },
      openMakeTransfer (item) {
        this.MakeTransferDialog = true
        this.itemDetails = item
      },
      openMakeRessaller (item) {
        this.MakeRessallerDialog = true
        this.itemDetails = item
      },
      openMoreDetails (item) {
        this.itemDetails = {}
        this.detailsDialog = true
        this.itemDetails = item
        this.itemDetails.action = 'view'
        console.log('this.itemDetails', this.itemDetails)
      },
      openEditDetails (item) {
        this.itemDetails = {}
        this.detailsDialog = true
        this.itemDetails = item
        this.itemDetails.action = 'edit'
        console.log('this.itemDetails', this.itemDetails)
      },
      setFilter (value) {
        this.filter = value
        this.fetchAllItems()
      },
      async fetchAllItems () {
        this.dataLoading = true
        this.loading = true
        const { page, itemsPerPage } = this.options
        const pageNumber = page - 1
        const list = await mainStockServices.getAllItems(itemsPerPage, page, pageNumber, this.filter)
        this.list = list.data.data
        this.total = list.data.total
        this.path = list.data.path
        this.dataLoading = false
        this.loading = false
      },
      async exportExel () {
        this.loading = true
        const listData = await mainStockServices.exportItems()
        console.log('listData', listData)
        const blob = new Blob([listData.data], { type: listData.headers['content-type'] })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const disposition = listData.headers['content-disposition']
        let filename = 'downloadedFile'
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        // Set the filename attribute and trigger download
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        // Cleanup
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
        // window.open(listData,'_blank')
        // window.FileReader.readAsBinaryString()
        // this.$router.go(listData)
      //   import('@/vendor/Export2Excel').then(excel => {
      //   const tHeader = this.header
      //   console.log('listData.data.data', listData.data)
      //   const list = listData.data.map(item => {
      //     return {
      //       code: item.id,
      //       fromTo: item.category.name,
      //       total: item.label,
      //       created: item.branch.name,
      //       employee: item.metal_type,
      //       gold_weight: item.gold_weight,
      //       item_total_after_profit: item.item_total_after_profit,
      //       product_date: item.product_date,
      //       status: item.status.name,
      //       }
      //   })
      //   const data = this.formatJson(list)
      //   excel.export_json_to_excel({
      //     header: tHeader,
      //     data,
      //     filename: this.filename,
      //     autoWidth: this.autoWidth,
      //     bookType: this.bookType,
      //   })
      //   this.loading = false
      // })
      },
      formatJson (jsonData) {
        return jsonData.map(v => {
          return Object.values(v)
        })
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
</style>
